import { DispatchFunction, GetStateFunction } from "../reducers";
import { getServerSettings, updateRespawnGrowth as apiUpdateRespawnGrowth, updateCustomRespawnLocation as updateCustomRespawnLocationApi, updateRespawnLocation as apiUpdateRespawnLocation, loadLocations as loadLocationsApi } from "../api/server-settings";
import { LOCATIONS_RECEIVED, SETTINGS_UPDATE } from "./types";
import { Location } from "../api/locations.types";
import { PATH_PERMISSION, INTERNAL_PERMISSION } from "../api/server-roles.types";
import { addError } from "./notifications";


export const loadServerSettings = (serverUuid: string) => async (dispatch: DispatchFunction) => {
    try {
        const settings = await getServerSettings(serverUuid);
        dispatch({ type: SETTINGS_UPDATE, payload: {serverUuid, settings } });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        } else {
            dispatch(addError('Failed to load server settings'));
        }
    }
}

export const updateRespawnLocation = (serverUuid: string, location: Location) => async (dispatch: DispatchFunction) => {
    if (location.id) {
        await updateCustomRespawnLocationApi(serverUuid, location.id);
    } else {
        await apiUpdateRespawnLocation(serverUuid, location.name);
    }
    
    dispatch({ type: SETTINGS_UPDATE, payload: { serverUuid, settings: { respawn_point: location?.id || location.name } } });
}

export const updateRespawnGrowth = (serverUuid: string, growth: string) => async (dispatch: DispatchFunction) => {
    await apiUpdateRespawnGrowth(serverUuid, growth);
    
    dispatch({ type: SETTINGS_UPDATE, payload: { serverUuid, settings: { respawn_growth: growth } } });
}

export const loadLocations = (serverUuid: string) => async (dispatch: DispatchFunction, getState: GetStateFunction) => {
    const { serverSettings, servers } = getState();
    if (serverSettings[serverUuid]?.locations.length > 0) {
        return;
    }
    const { serverDetail } = servers;
    const permissions = serverDetail?.server?.permissions || [];
    
    try {
        const locations = await loadLocationsApi(serverUuid, permissions.includes(PATH_PERMISSION.LISTPOI), permissions.includes(INTERNAL_PERMISSION.READ_LOCATIONS));
        const locationOptions = locations.filter(item => Boolean(item.location)).map((location) => {
          return {
            label: location.name,
            value: location.name,
            item: location,
          };
        });
        dispatch({ type: LOCATIONS_RECEIVED, payload: {serverUuid, locations: locationOptions} });
    } catch (e: any) {
        if (e.userFriendly) {
            dispatch(addError(e.message));
        }
        dispatch({ type: LOCATIONS_RECEIVED, payload: {serverUuid, locations: []} });
    }
}