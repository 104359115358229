import React from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { withAuthProps } from '../../../common/hooks/withAuth';
import { withSmallScreenCheck } from '../../../common/hooks/withSmallScreenCheck';
import { Page } from '../../common/Page';
import { ReduxState } from '../../../reducers';
import { connect } from 'react-redux';
import { ServerModel } from '../../../api/server.types';
import { getServerIni, updateServerIniForName } from '../../../api/server-ini';
import { IniGameName, ServerIni } from '../../../api/server-ini.types';
import EditorTabPanel from './EditorTabPanel';
import { addError, addSuccess } from '../../../actions/notifications';
import { ConfirmationDialog } from '../../../components/common/Modal';
import CliToolPanel from './CliToolPanel';
import { sendRestartCommand } from '../../../api/server-commands';
import { INTERNAL_PERMISSION } from '../../../api/server-roles.types';
import { ServerRestartModel } from '../common/ServerRestartModel';

interface ReduxStateProps {
  server: ServerModel | undefined;
}

interface ReduxActionProps {
  addError: (message: string) => void;
  addSuccess: (message: string) => void;
}

interface ComponentProps extends withAuthProps {}

interface IniState {
  game: ServerIni;
  'game-user-settings': ServerIni;
  commands: ServerIni;
  iniApiKey: string;
}

interface ComponentState extends IniState {
  value: number;
  showRestartDialog: boolean;
}

type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps;

const defaultIni: ServerIni = { server_uuid: '', name: '', contents: '' };

class EditorTabComponent extends React.Component<CompositeProps, ComponentState> {

  constructor(props: CompositeProps) {
    super(props);
    this.state = {
      showRestartDialog: false,
      value: 0,
      iniApiKey: '',
      game: { server_uuid: '', name: '', contents: '' },
      'game-user-settings': { server_uuid: '', name: '', contents: '' },
      commands: { server_uuid: '', name: '', contents: '' },
    };
  }

  loadServerIni = async () => {
    const { server } = this.props;
    if (!server) {
      return;
    }
    const { uuid } = server;

    try {
      const set = await getServerIni(uuid);
      this.setState({
        game: set.game ? set.game : defaultIni,
        'game-user-settings': set.gameUserSettings ? set.gameUserSettings : defaultIni,
        commands: set.commands ? set.commands : defaultIni,
      });
    } catch(e: any) {
      if (e.userFriendly) {
        this.props.addError(e.message);
      } else {
        this.props.addError('An error occurred while deleting the invitation, please try again.');
      }
    }
  }

  handleChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({ value: newValue });
  };

  componentDidMount() {
    this.loadServerIni();
  }

  componentDidUpdate(prevProps: Readonly<CompositeProps>, prevState: Readonly<ComponentState>, snapshot?: any): void {
    if (prevProps.server !== this.props.server) {
      this.loadServerIni();
    }
  }

  onSubmitSave = async (name: IniGameName, contents: string) => {
    const { server } = this.props;
    if (!server) {
      return;
    }
    const { uuid } = server;

    try {
      const ini = await updateServerIniForName(uuid, name, contents);
      this.props.addSuccess('Successfully saved changes');
      this.onPostSaveUpdate(name, ini.contents);
    } catch(e: any) {
      if (e.userFriendly) {
        this.props.addError(e.message);
      } else {
        this.props.addError('An error occurred while deleting the invitation, please try again.');
      }
    }
  }

  onPostSaveUpdate = (name: IniGameName, contents: string) => {
    switch (name) {
      case IniGameName.GAME:
        this.setState({ game: { ...this.state.game, contents } });
        break;
      case IniGameName.GAME_USER_SETTINGS:
        this.setState({ 'game-user-settings': { ...this.state['game-user-settings'], contents } });
        break;
      case IniGameName.COMMANDS:
        this.setState({ commands: { ...this.state.commands, contents } });
        break;
    }
  }

  onShowRestartDialog = () => {
    this.setState({ showRestartDialog: true });
  }

  onCloseRestartDialog = () => {
    this.setState({ showRestartDialog: false });
  }

  onConfirmRestart = async(seconds: number) => {
    this.onCloseRestartDialog();
    
    if (!this.props.server) {
      return;
    }

    const { uuid } = this.props.server;

    try {
      await sendRestartCommand(uuid || '', seconds);
      this.props.addSuccess('The restart command has been issued'); 
    } catch(e: any) {
      if (e.userFriendly) {
        this.props.addError(e.message);
      } else {
        this.props.addError('An error occurred while deleting the invitation, please try again.');
      }
    }
  }

  render = () => {
    const { server } = this.props;
    if (!server) {
      return null;
    }

    const { value, showRestartDialog, game, "game-user-settings": gameUserSettings, commands } = this.state;
    const canManageCli = server.permissions.includes(INTERNAL_PERMISSION.MANAGE_INI_CLI_TOOL);

    return (
        <Page>
            <Box sx={{ p: 1, display: 'flex' }}>
              <ServerRestartModel visible={showRestartDialog} onClose={this.onCloseRestartDialog} onConfirmed={this.onConfirmRestart} />
              <Tabs value={value} onChange={this.handleChange} centered>
                <Tab label="Game.ini" />
                <Tab label="GameUserSettings.ini" />
                <Tab label="Commands.ini" />
                {canManageCli && <Tab label="CLI Tool" />}
              </Tabs>
            </Box>
              {value === 0 && <EditorTabPanel name={IniGameName.GAME} content={game.contents} onSave={this.onSubmitSave} onServerRestart={this.onShowRestartDialog} />}
              {value === 1 && <EditorTabPanel name={IniGameName.GAME_USER_SETTINGS} content={gameUserSettings.contents} onSave={this.onSubmitSave} onServerRestart={this.onShowRestartDialog} />}
              {value === 2 && <EditorTabPanel name={IniGameName.COMMANDS} content={commands.contents} onSave={this.onSubmitSave} onServerRestart={this.onShowRestartDialog} />}
              {canManageCli && value === 3 && <CliToolPanel />}
        </Page>
    );
  }
}


const mapStateToProps = ({ servers  }: ReduxState) => ({
  server: servers?.serverDetail?.server,
});

export const ServerEditorTab =  withSmallScreenCheck(connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, { addSuccess, addError })(EditorTabComponent));