import React from 'react';
import { connect } from 'react-redux';
import { withAuthProps } from '../../../common/hooks/withAuth';
import { ReduxState } from '../../../reducers';
import { withRouter, WithRouterProps } from '../../../common/hooks/withParams';
import { ServerDetailsModel, ServerModel } from '../../../api/server.types';
import { Page } from '../../common/Page';
import ServerCard from '../ServerCard';
import { loadServerDetails, updateRCONConfiguration, updateServer, removeRCONConfiguration } from '../../../actions/server';
import { AddEditServerDialog } from '../AddEditServerDialog';
import ConnectivityCard from './ConnectivityCard';
import { Box, Typography } from '@mui/material';
import { EditRCONDialog } from './EditRCONDialog';
import { ConfirmationDialog } from '../../common/Modal';
import RequiresPermission from '../../common/RequiresPermission';
import { INTERNAL_PERMISSION } from '../../../api/server-roles.types';

interface ReduxStateProps {
  serverDetails?: ServerDetailsModel;
  rconUpdating: boolean;
  loading: boolean;
}

interface ReduxActionProps {
  loadServerDetails: (serverUuid: string) => void;
  removeRCONConfiguration: (serverUuid: string) => void;
  updateServer: (serverUuid: string, serverData: { name: string; description: string; logo: File | null }) => void;
  updateRCONConfiguration: (serverUuid: string, connection: { host: string; port: number; password: string }) => void;
 }

interface ComponentProps {}

interface ComponentState {
  server?: ServerModel;
  rcon: {
    configured: boolean;
    connected: boolean;
  }
  registered: boolean;
  owner: boolean;
  showEditDialog: boolean;
  showRCONDialog: boolean;
  showConfirmDeleteRCONDialog: boolean;
 }

export type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps & withAuthProps & WithRouterProps;

class ServerConnectivity extends React.Component<CompositeProps, ComponentState> {
  state = {
    server: undefined,
    owner: false,
    showEditDialog: false,
    showRCONDialog: false,
    registered: false,
    showConfirmDeleteRCONDialog: false,
    rcon: {
      configured: false,
      connected: false,
    }
  }

  componentDidMount(): void {
    if (!this.props.params.serverUuid) {
      return;
    }
    this.props.loadServerDetails(this.props.params.serverUuid);
  }

  componentDidUpdate(prevProps: Readonly<CompositeProps>, prevState: Readonly<ComponentState>, snapshot?: any): void {
      if (prevProps.loading && !this.props.loading || prevProps.serverDetails !== this.props.serverDetails) {
        const { serverDetails } = this.props;
        if (!serverDetails) {
          this.props.navigate('/');
          return;
        }
        this.setState({
          server: serverDetails.server,
          rcon: serverDetails.rcon,
          registered: serverDetails.registered,
          owner: serverDetails.server.roles.includes('owner')
        });
      }
  }
  onEditServer = () => {
    this.setState({ showEditDialog: true });
  }

  onCloseEditDialog = () => {
    this.setState({ showEditDialog: false });
  }

  onShowRCONDialog = () => {
    this.setState({ showRCONDialog: true });
  }

  onCloseRCONDialog = () => {
    this.setState({ showRCONDialog: false });
  }

  onSaveRCONDialog = (connection: { host: string, port: number, password: string }) => {
    this.onCloseRCONDialog();
    if (!this.state.server) {
      return;
    }
    this.props.updateRCONConfiguration((this.state.server as ServerModel).uuid, connection);
  }

  onSaveEditDialog = (serverData: { name: string; description: string; logo: File | null }) => {
    const { server } = this.state;
    if (!server) {
      return;
    }
    this.onCloseEditDialog();
    this.props.updateServer((server as ServerModel).uuid, serverData);
  }

  onConfirmDeleteRCON = () => {
    this.onCloseDeleteRCON();
    if (!this.state.server) {
      return;
    }
    this.props.removeRCONConfiguration((this.state.server as ServerModel).uuid);
  }

  onCloseDeleteRCON = () => {
    this.setState({ showConfirmDeleteRCONDialog: false });
  }

  onShowConfirmDeleteRCON = () => {
    this.setState({ showConfirmDeleteRCONDialog: true });
  }

  render() {
    const { server, rcon, showEditDialog, showRCONDialog, registered } = this.state;
    if (!server) {
      return null;
    }
    const canManageRcon = (server as ServerModel).permissions.includes(INTERNAL_PERMISSION.MANAGE_RCON);
    return (
      <Page>
        <AddEditServerDialog 
          visible={showEditDialog}
          onClose={this.onCloseEditDialog}
          onConfirmed={this.onSaveEditDialog}
          item={server}
        />
        <EditRCONDialog
          visible={showRCONDialog}
          onClose={this.onCloseRCONDialog}
          onConfirmed={this.onSaveRCONDialog}
        />
        <ConfirmationDialog
          id={'delete-rcon'}
          title="Delete RCON Configuration"
          visible={this.state.showConfirmDeleteRCONDialog}
          onConfirmed={this.onConfirmDeleteRCON}
          onClose={this.onCloseDeleteRCON}>
            <Typography variant="body1">
              Are you sure you want to delete the RCON configuration for this server?
              <br /><br />
              TitanDash will loose the ability to control this server.
            </Typography>
        </ConfirmationDialog>
        <Box sx={{ maxWidth: '70rem'}}>
          <RequiresPermission requires={[INTERNAL_PERMISSION.MANAGE_RCON, INTERNAL_PERMISSION.READ_RCON]} requiresRule='any'>
            <ConnectivityCard registered={registered} configured={rcon.configured} connected={rcon.connected} showConfigure={false} showDelete={canManageRcon} updating={this.props.rconUpdating} onConfigure={this.onShowRCONDialog} onDelete={this.onShowConfirmDeleteRCON}/>
          </RequiresPermission>
        </Box>
      </Page>
    )
  }
}


const mapStateToProps = ({ servers }: ReduxState) => {
  return {
    serverDetails: servers.serverDetail,
    loading: servers.loadingServerDetails,
    rconUpdating: servers.rconUpdating,
  }
};

export const ServerConnectivityTab = withRouter(connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, { loadServerDetails, updateServer, updateRCONConfiguration, removeRCONConfiguration })(ServerConnectivity));