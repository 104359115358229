import { AxiosError } from "axios";

export class GenericError extends Error {
    userFriendly: boolean = true;
    constructor(message: string) {
        super(message)
    }
}

export class FriendlyApiError extends GenericError {
    status: number
    error: string
    error_description: string
    message: string

    constructor(axiosError: AxiosError) {
        super((axiosError?.response?.data as any)?.message ?? 'Unknown Error')
        const { error, error_description, message } = (axiosError?.response?.data as any) ?? {}
        this.error = error;
        this.error_description = error_description;
        this.message = message;
        this.status = axiosError?.response?.status ?? -1
    }
  }
  