import React from "react";
import { TextField, Typography } from "@mui/material";
import { ConfirmationDialog } from "../../../components/common/Modal";

export interface ServerRestartModelProps {
    visible: boolean;
    onClose: () => void;
    onConfirmed: (seconds: number) => void;
}

export interface ServerRestartModelState {
    restartSeconds: number | null;
}
export class ServerRestartModel extends React.Component<ServerRestartModelProps, ServerRestartModelState> {
    constructor(props: ServerRestartModelProps) {
        super(props);
        this.state = {
            restartSeconds: 60
        };
    }

    componentDidUpdate(prevProps: Readonly<ServerRestartModelProps>, prevState: Readonly<ServerRestartModelState>, snapshot?: any): void {
        if (prevProps.visible !== this.props.visible && this.props.visible) {
            this.setState({ restartSeconds: 60 });
        }
    }
    onRestartSecondsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            this.setState({ restartSeconds: null });
            return;
        }
        const seconds = parseInt(event.target.value);
        if (isNaN(seconds)) {
            return;
        }

        this.setState({ restartSeconds: seconds });
    }

    onCloseRestartDialog = () => {
        this.props.onClose();
    }

    onConfirmRestartDialog = () => {
        const { restartSeconds } = this.state;
        const seconds = restartSeconds ?? 0;
        this.props.onConfirmed(seconds);
    }

    render() {
        const { visible } = this.props;
        const { restartSeconds } = this.state;
        return (
            <ConfirmationDialog
                id={'confirm-restart'}
                title={`Server Restart`}
                visible={visible}
                okButtonText="Issue Restart"
                cancelButtonText="Nevermind"
                onConfirmed={this.onConfirmRestartDialog}
                onClose={this.onCloseRestartDialog}>
                <Typography>
                    In how many seconds should the server restart?
                </Typography>
                <TextField
                    sx={{ mt: 1 }}
                    label="Seconds"
                    type="number"
                    value={restartSeconds}
                    onChange={this.onRestartSecondsChanged} 
                />
            </ConfirmationDialog>
        )
    }
}