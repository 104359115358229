import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, TextField, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip, FormControlLabel, Checkbox } from '@mui/material';
import { getServerMembers, updateServerRolesForMember, removeServerMember } from '../../../actions/server-members'; // Adjust the import paths as needed
import { ConfirmationDialog } from '../../common/Modal';
import { addError } from '../../../actions/notifications';
import { ReduxState } from '../../../reducers';
import { ServerMember } from '../../../api/server-members.types';
import { ServerModel } from '../../../api/server.types';
import { ServerRole } from '../../../api/server-roles.types';


interface ServerMembersProps {
  server: ServerModel;
  serverMembers: ServerMember[]
  roles: ServerRole[]
  serverMemberRoles: string;
  userRoles: string[];
  loading: boolean;
  userUuid: string;
  getServerMembers(serverUuid: string): void;
  removeServerMember: (serverUuid: string, uuid: string) => void;
  updateServerRolesForMember: (serverUuid: string, uuid: string, roles: string[]) => void;
  addError: (error: string) => void;
}

interface ServerMembersState {
  permissionUpdatesModalOpen: boolean;
  confirmPermissionUpdatesOpen: boolean;
  isRemoveConfirmationOpen: boolean;
  currentServerMember: ServerMember | null;
  removedRoles: string[];
  addedRoles: string[];
  searchTerm: string;
  serverMembers: ServerMember[];
  memberRoles: string[];
}

class ServerMembersComponent extends Component<ServerMembersProps, ServerMembersState> {
  state: ServerMembersState = {
    isRemoveConfirmationOpen: false,
    permissionUpdatesModalOpen: false,
    confirmPermissionUpdatesOpen: false,
    currentServerMember: null,
    removedRoles: [],
    addedRoles: [],
    serverMembers: [],
    memberRoles: [],
    searchTerm: ''
  };

  componentDidMount(): void {
    const { server } = this.props;
    if (!server) {
      return;
    }
    const { uuid: serverUuid } = server;
    this.props.getServerMembers(serverUuid);
  }


  componentDidUpdate(prevProps: Readonly<ServerMembersProps>, prevState: Readonly<ServerMembersState>, snapshot?: any): void {
    if (this.props.server && prevProps.server !== this.props.server) {
      const { uuid: serverUuid } = this.props.server;
      this.props.getServerMembers(serverUuid);
    }

    if (prevProps.serverMembers !== this.props.serverMembers) {
      this.filterServerMembers(this.state.searchTerm);
    }
  }

  filterServerMembers = (searchTerm: string) => {
    const { serverMembers } = this.props;
    if (searchTerm === '') {
      this.setState({ serverMembers });
      return;
    }
    const filteredServerMembers = serverMembers?.filter((serverMember) => serverMember.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) || serverMember?.email?.toLowerCase()?.includes(searchTerm?.toLowerCase()));
    this.setState({ serverMembers: filteredServerMembers });
  }

  onShowConfirmRemoveServerMember = (serverMember: ServerMember) => {
    this.setState({ isRemoveConfirmationOpen: true, currentServerMember: serverMember });
  };

  onConfirmedRemoveServerMember = () => {
    const { server } = this.props;
    if (!server) {
      return;
    }
    const { uuid: serverUuid } = server;
    const { currentServerMember } = this.state;
    if (currentServerMember !== null) {
      this.props.removeServerMember(serverUuid, currentServerMember.user_uuid);
      this.onCloseConfirmRemove();
    }
  };

  onCloseConfirmRemove = () => this.setState({ isRemoveConfirmationOpen: false, currentServerMember: null, removedRoles: [], addedRoles: [] });

  onShowConfirmUpdates = () => {
    const { currentServerMember, memberRoles } = this.state;
    if (!currentServerMember) {
      return;
    }
    const addedRoles = memberRoles.filter((role) => !currentServerMember.roles.includes(role));
    const removedRoles = currentServerMember.roles.filter((role) => !memberRoles.includes(role));
    if (removedRoles.length > 0 || addedRoles.length > 0) {
      this.setState({ confirmPermissionUpdatesOpen: true });
    } else {
      this.onClosePermissionUpdates();
    }
  }

  onCloseConfirmUpdates = () => {
    this.setState({ confirmPermissionUpdatesOpen: false });
  }

  onClosePermissionUpdates = () => {
    this.setState({ permissionUpdatesModalOpen: false, currentServerMember: null, removedRoles: [], addedRoles: [] });
  }

  onShowRolesModal = (serverMember: ServerMember) => {
    this.setState({ permissionUpdatesModalOpen: true, memberRoles: [...serverMember.roles], currentServerMember: serverMember });
  }

  onConfirmedUpdates = () => {
    const { server } = this.props;
    if (!server) {
      return;
    }
    const { uuid: serverUuid } = server;
    const { currentServerMember, memberRoles } = this.state;
    if (!currentServerMember) {
      return;
    }
    const { user_uuid } = currentServerMember;
    this.props.updateServerRolesForMember(serverUuid, user_uuid, memberRoles);

    this.onCloseConfirmUpdates();
    this.onClosePermissionUpdates();
  }

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    this.setState({ searchTerm });
    this.filterServerMembers(searchTerm);
  }

  handleAdminCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { memberRoles } = this.state;
    if (memberRoles.includes('admin')) {
      this.setState({ memberRoles: memberRoles.filter((role) => role !== 'admin') });
    } else {
      this.setState({ memberRoles: [...memberRoles, 'admin'] });
    }
  }

  handleRoleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { memberRoles } = this.state;
    const { value } = event.target;
    if (memberRoles.includes(value)) {
      this.setState({ memberRoles: memberRoles.filter((role) => role !== value) });
    } else {
      this.setState({ memberRoles: [...memberRoles, value] });
    }
  }

  render() {
    const { isRemoveConfirmationOpen, permissionUpdatesModalOpen, currentServerMember, confirmPermissionUpdatesOpen, memberRoles, serverMembers } = this.state;
    const { loading, userRoles, userUuid } = this.props;

    return (
      <Box>
        <ConfirmationDialog
          id="confirm-remove"
          title="Remove Member"
          visible={isRemoveConfirmationOpen}
          okButtonText='Remove'
          cancelButtonText='Cancel'
          onConfirmed={this.onConfirmedRemoveServerMember}
          onClose={this.onCloseConfirmRemove}>
          <Box>
            <Typography>Are you sure you want to remove {currentServerMember?.name} from the server?</Typography>
          </Box>
        </ConfirmationDialog>
        <ConfirmationDialog
          id="confirm-updates"
          title="Role Updates"
          visible={confirmPermissionUpdatesOpen}
          okButtonText='Update'
          cancelButtonText='Cancel'
          onConfirmed={this.onConfirmedUpdates}
          onClose={this.onCloseConfirmUpdates}>
          <Box>
            <Typography>Are you sure you want to save these changes?</Typography>
          </Box>
        </ConfirmationDialog>
        <ConfirmationDialog
          id="update-permissions"
          title="Permission Updates"
          okButtonText='OK'
          cancelButtonText='Cancel'
          visible={permissionUpdatesModalOpen}
          onConfirmed={this.onShowConfirmUpdates}
          onClose={this.onClosePermissionUpdates}>
          <Box>
            <Typography variant="h6">Roles</Typography>
            {(userRoles?.includes('owner') || userRoles?.includes('admin'))  && <FormControlLabel control={<Checkbox checked={memberRoles.includes('admin')} onChange={this.handleAdminCheckbox} />} label="Admin" />}
            {this.props?.roles?.map((role) => {
              return <FormControlLabel key={role.uuid} control={<Checkbox checked={memberRoles.includes(role.uuid)} onChange={this.handleRoleCheckbox} />} label={role.name} value={role.uuid} />
            })}
          </Box>
        </ConfirmationDialog>
        <Typography variant="h5" sx={{ mb: 2 }}>Server Members</Typography>
        <TextField
          fullWidth
          label="Search Server Members"
          variant="outlined"
          margin="normal"
          onChange={this.handleSearchChange}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Display Name</TableCell>
                <TableCell>Role(s)</TableCell>
                {userRoles.includes('owner') && <TableCell>Email</TableCell>}
                <TableCell>Actions</TableCell>
              </TableRow>
              </TableHead>
          <TableBody>
            {serverMembers?.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>{loading ? '' : 'No members found'}</TableCell>
              </TableRow>
            )}
            {serverMembers?.map((serverMember) => (
              <TableRow key={serverMember.user_uuid}>
                <TableCell>{serverMember.name}</TableCell>
                <TableCell>
                    
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
                      {serverMember?.roleNames?.map((role, index) => (
                        <Chip key={role} label={role} color="primary" size="small" />
                      ))}
                    </Box>
                </TableCell>
                {userRoles.includes('owner') && <TableCell>{serverMember.email}</TableCell>}
                <TableCell>
                  {!serverMember.roles.includes('owner') && serverMember.user_uuid !== userUuid && <Button onClick={() => this.onShowRolesModal(serverMember)}>{'Roles'}</Button>}
                  {!serverMember.roles.includes('owner') && serverMember.user_uuid !== userUuid && <Button onClick={() => this.onShowConfirmRemoveServerMember(serverMember)}>Remove</Button>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>

    );
  }
}

const mapStateToProps = ({ auth, serverMembers, servers, serverRoles }: ReduxState,) => {
  const { serverDetail } = servers;
  const server = serverDetail?.server ?? { uuid: '', roles: [] };
  const serverUuid = server?.uuid;
  const { items, loadingServerMembers } = serverMembers[serverUuid] ?? { items: [], loadingServerMembers: false };
  const { items: roles } = serverRoles[serverUuid] ?? { items: [] };

  return {
    userUuid: auth.user?.id,
    server,
    loading: loadingServerMembers,
    serverMembers: items,
    roles,
    userRoles: server.roles ?? [],
  };
}

export default connect(mapStateToProps, {getServerMembers, removeServerMember, addError, updateServerRolesForMember})(ServerMembersComponent as any);