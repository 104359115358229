import axios from 'axios'
import moment from 'moment'

import { ServerMember } from './server-members.types'
import { FriendlyApiError, GenericError } from './types'

const SERVER_ENDPOINT = `/api/server`
const MEMBERS_ENDPOINT = `members`
const ROLE_ENDPOINT = `role`
const ROLES_ENDPOINT = `roles`


export const getServerMembers = async(serverUuid: string): Promise<ServerMember[]> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/${MEMBERS_ENDPOINT}`)
        const { items } = data
        if (!items) {
            throw new GenericError('Failed to retrieve users.')
        }

        return items.map(({ user_uuid, server_uuid, name, email, roleNames, roles, permissions}: ServerMember) => {
            return {
                user_uuid,
                server_uuid,
                name,
                email,
                roleNames,
                roles,
                permissions,
            }
        })
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}
export const removeServerMember = async(serverUuid: string, uuid: string): Promise<void> => {
    try {
        await axios.delete(`${SERVER_ENDPOINT}/${serverUuid}/${MEMBERS_ENDPOINT}/${uuid}`)
    } catch(e: any) {
        console.error(e)
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const addRole = async(serverUuid: string, uuid: string, role: string): Promise<void> => {
    try {
        await axios.put(`${SERVER_ENDPOINT}/${serverUuid}/${MEMBERS_ENDPOINT}/${uuid}/${ROLE_ENDPOINT}/${role}`, { })
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const removeRole = async(serverUuid: string, uuid: string, role: string): Promise<void> => {
    try {
      await axios.delete(`${SERVER_ENDPOINT}/${serverUuid}/${MEMBERS_ENDPOINT}/${uuid}/${ROLE_ENDPOINT}/${role}`)
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const updateServerRolesForMember = async(serverUuid: string, uuid: string, roles: string[]): Promise<ServerMember> => {
    try {
        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${MEMBERS_ENDPOINT}/${uuid}/${ROLES_ENDPOINT}`, { roles })
        const { success, item } = data
        if (!success || !item) {
            throw new GenericError('Failed to update roles for user.')
        }

        return item as ServerMember
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}