import axios from 'axios'

import { AlderonUser, AuthenticationResponse, User } from './auth.types'
import { FriendlyApiError } from './types'
const AUTH_ENDPOINT = `/api/auth/login`
const REFRESH_ENDPOINT = `/api/auth/refresh-token`
const REGISTER_ENDPOINT = `/api/auth/register`
const USER_ENDPOINT = `/api/user`
const USER_ALDERON_ENDPOINT = `/api/user/alderon`
const INITIATE_RESET_PASSWORD = `/api/auth/initiate-reset-password`
const CONFIRM_RESET_PASSWORD = `/api/auth/confirm-reset-password`

const DISCORD_LOGIN = `/api/auth/discord/login`
const DISCORD_REGISTER = `/api/auth/discord/register`

const ALDERON_CONNECTION_ENDPOINT = `/api/alderon/connect`

export const authenticate = async(email: string, password: string): Promise<AuthenticationResponse> => {
    const payload = {
        email,
        password,
    }
    try {
        const { data } = await axios.post(AUTH_ENDPOINT, payload)

        return data as AuthenticationResponse
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}
export const refreshToken = async(id: string, refreshToken: string): Promise<AuthenticationResponse> => {
    const payload = {
        id,
        refreshToken,
    }
    try {
        const { data } = await axios.post(REFRESH_ENDPOINT, payload)

        return data as AuthenticationResponse
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const register = async(name: string, email: string, password: string, confirmPassword: string): Promise<AuthenticationResponse> => {
    const params = new URLSearchParams(window.location.search)
    const invitation = params.get('invitation') ?? undefined
    const payload = {
        name,
        email,
        password,
        confirmPassword,
        invitationCode: invitation,
    }
    try {
        const { data } = await axios.post(REGISTER_ENDPOINT, payload)

        return data as AuthenticationResponse
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const userDetails = async(accessToken: string): Promise<User> => {
    const { data } = await axios.get(USER_ENDPOINT, { headers: { 'x-access-token': accessToken }})

    return data as User
}

export const getAlderonUserDetails = async(accessToken: string): Promise<AlderonUser> => {
    const { data } = await axios.get(USER_ALDERON_ENDPOINT, { headers: { 'x-access-token': accessToken }})

    return data as AlderonUser
}

export class AlderonConnectionError extends Error {
    constructor(message: string) {
        super(message)
        this.name = 'AlderonConnectionError'
    }
}

export const alderonConnect = async (email: string, password: string) => {
    try {
        const { data } = await axios.post(ALDERON_CONNECTION_ENDPOINT, { email, password })
        if (data.connected !== true) {
            throw new AlderonConnectionError('Failed to connect to Alderon')
        }

        return true;
    } catch(e: any) {
        if (e.request.status === 400) {
            const message = e.response.data?.message ?? 'Failed to connect to Alderon'
            throw new AlderonConnectionError(message)
        }

        throw new AlderonConnectionError('Failed to connect to Alderon')
    }

}

export const alderonDisconnect = async () => {
    try {
        const { data } = await axios.delete(ALDERON_CONNECTION_ENDPOINT)
        if (data.connected !== false) {
            throw new AlderonConnectionError('Failed to disconnect from Alderon')
        }

        return data.connected === false;
    } catch(e: any) {
        if (e.request.status === 400) {
            const message = e.response.data?.message ?? 'Failed to disconnect from Alderon'
            throw new AlderonConnectionError(message)
        }

        throw new AlderonConnectionError('Failed to disconnect from Alderon')
    }

}

export const initiateResetPassword = async (email: string) => {
    try {
        const { data } = await axios.post(INITIATE_RESET_PASSWORD, { email })
        if (data.success !== true) {
            throw new Error('Failed to initiate password reset')
        }

        return data.success === true;
    } catch(e: any) {
        throw new Error('Failed to initiate password reset')
    }

}

export const resetPassword = async (id: string, code: string, password: string, confirmPassword: string) => {
    try {
        const { data } = await axios.post(CONFIRM_RESET_PASSWORD, { id, code, password, confirmPassword })
        if (data.success !== true) {
            throw new Error('Failed to reset password, please try again shortly')
        }

        return data.success === true;
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new Error(e.response.data?.message ?? 'Failed to reset password, please try again shortly')
        }
        throw new Error('Failed to reset password, please try again shortly')
    }

}


export const registerDiscord = async (state: string) => {
    try {
        const { data } = await axios.post(DISCORD_REGISTER, { state })
        
        return data as AuthenticationResponse
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}


export const loginDiscord = async (state: string): Promise<AuthenticationResponse> => {
    try {
        const { data } = await axios.post(DISCORD_LOGIN, { state })
        
        return data as AuthenticationResponse
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const hasScope = (scope: string, query: string): boolean => {
    return scope?.includes(query);
}