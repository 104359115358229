import { INTERNAL_PERMISSION, PATH_PERMISSION } from "./server-roles.types";

export interface ServerModel {
    id: number;
    uuid: string;
    owner_uuid: string;
    name: string;
    description: string;
    logo: string;
    roles: string[];
    roleIds: string[];
    permissions: string[];
}

export interface RCONDetails {
    configured: boolean;
    connected: boolean;
}
export interface ServerSessionDTO {
  pot_server_uuid: string;
  name: string;
  map: string;
  game_mode: string;
  branch: string;
  player_limit: number;
  ip_address: string;
  start_time: string;
  end_time?: string | null;
  seconds_till_restart?: number | null;
  force_stop?: boolean | null;
}
export interface ServerDetailsModel {
    server: ServerModel;
    rcon: RCONDetails;
    registered: boolean;
    agidVerified: boolean;
    session?: ServerSessionDTO;
    crashLoop?: boolean;
}

export interface TabItem {
    label: string;
    endpoint: string;
    permissions: string[];
    hideTabs?: boolean;
}

export const MAP_ALIAS: { [key: string]: string } = {
  'Gondwa': 'Island'
}

export const TAB_SECTIONS: ({ name: string, items: TabItem[] })[] = [
  {
    name: 'Server Management',
    items: [{
      label: 'Active Players',
      endpoint: 'home',
      permissions: [INTERNAL_PERMISSION.HOME_TAB],
    },
    {
      label: 'Custom Locations',
      endpoint: 'locations',
      permissions: [INTERNAL_PERMISSION.LOCATIONS_TAB],
    },
    {
      label: 'Server Settings',
      endpoint: 'settings',
      permissions: [INTERNAL_PERMISSION.SETTINGS_TAB],
    },
    {
      label: 'Activity Logs',
      endpoint: 'logs',
      permissions: [INTERNAL_PERMISSION.ACTIVITY_LOGS_TAB],
    },
    {
      label: `Config Files`,
      endpoint: 'configs',
      permissions: [INTERNAL_PERMISSION.SERVER_CONFIG_TAB],
    },
    {
      label: `Webhooks`,
      endpoint: 'webhooks',
      permissions: [INTERNAL_PERMISSION.READ_WEBHOOKS, INTERNAL_PERMISSION.MANAGE_WEBHOOKS],
    },
    {
      label: 'Connectivity',
      endpoint: 'connectivity',
      permissions: [INTERNAL_PERMISSION.CONNECTIVITY_TAB],
    }]
  },
  {
    name: 'Site Management',
    items: [{
        label: 'Site Roles',
        endpoint: 'roles',
        permissions: [INTERNAL_PERMISSION.ROLES_TAB],
      },
      {
        label: 'Site Members',
        endpoint: 'members',
        permissions: [INTERNAL_PERMISSION.MEMBERS_TAB],
      }]
  },
  {
    name: 'Misc',
    items: [{
      label: 'Delete Server',
      endpoint: 'delete',
      permissions: [INTERNAL_PERMISSION.DELETE_SERVER],
    },
    {
        label: 'Exit Admin',
        endpoint: 'exit',
        permissions: [INTERNAL_PERMISSION.HOME_TAB],
      }]
  }
]

  export interface WebhookEndpoints {
    PlayerLogin: string;
    PlayerLogout: string;
    PlayerLeave: string;
    PlayerKilled: string;
    PlayerChat: string;
    PlayerRespawn: string;
    ServerRestart: string;
    AdminSpectate: string;
    AdminCommand: string;
    PlayerDamagedPlayer: string;
    ServerRestartCountdown: string;
    PlayerWaystone: string;
    PlayerQuestComplete: string;
    PlayerQuestFailed: string;
  }