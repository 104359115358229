import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { withAuthProps } from '../../../common/hooks/withAuth';
import { withSmallScreenCheck } from '../../../common/hooks/withSmallScreenCheck';
import { Page } from '../../common/Page';
import { ReduxState } from '../../../reducers';
import { connect } from 'react-redux';
import { ServerModel } from '../../../api/server.types';
import { addError, addSuccess } from '../../../actions/notifications';
import ServerLoginsTab from '../logins/ServerLoginsTab';

interface ReduxStateProps {
  server: ServerModel | undefined;
}

interface ReduxActionProps {
  addError: (message: string) => void;
  addSuccess: (message: string) => void;
}

interface ComponentProps extends withAuthProps {}


interface ComponentState  {
  value: number;
}

type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps;

class ActivityLogs extends React.Component<CompositeProps, ComponentState> {

  constructor(props: CompositeProps) {
    super(props);
    this.state = {
      value: 0,
    };
  }

  handleChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({ value: newValue });
  };

  render = () => {
    const { server } = this.props;
    if (!server) {
      return null;
    }
    const { value } = this.state;

    return (
        <Box>
            <Box sx={{ p: 1, display: 'flex' }}>
              <Tabs value={value} onChange={this.handleChange} centered>
                <Tab label="Logins" />
                <Tab label="Spawns" />
              </Tabs>
            </Box>
              {value === 0 && <ServerLoginsTab />}
              {value === 1 && null}
        </Box>
    );
  }
}


const mapStateToProps = ({ servers  }: ReduxState) => ({
  server: servers?.serverDetail?.server,
});

export const ActivityLogsTab =  withSmallScreenCheck(connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, { addSuccess, addError })(ActivityLogs));