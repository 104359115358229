import React from 'react';
import { Box } from '@mui/material';
import { withAuthProps } from '../../../common/hooks/withAuth';
import { withSmallScreenCheck } from '../../../common/hooks/withSmallScreenCheck';
import { Page } from '../../common/Page';
import { CreatorModes } from './CreatorModes';
import { ReduxState } from '../../../reducers';
import { connect } from 'react-redux';
import { loadServerSettings } from '../../../actions/server-settings';
import { RespawnLocation } from './RespawnLocation';
import { RespawnGrowth } from './RespawnGrowth';
import { ServerModel } from '../../../api/server.types';
import WebhooksComponent from './WebhooksComponent';

interface ReduxStateProps {
  server: ServerModel
}

interface ReduxActionProps {
  loadServerSettings: (serverUuid: string) => void;
  loadServerLocations: (serverUuid: string) => void;
}

interface ComponentProps extends withAuthProps {}

interface SettingsState {
}

export type SettingsProps = ComponentProps & ReduxStateProps & ReduxActionProps;

class SettingsComponent extends React.Component<SettingsProps, SettingsState> {
  playerPollingId: any

  constructor(props: any) {
    super(props)
    this.state = {
      players: [],
    }
  }

  componentDidMount(): void {
    const { server } = this.props;
    if (!server) {
      return;
    }
    this.props.loadServerSettings(server.uuid);
  }

  componentDidUpdate(prevProps: Readonly<SettingsProps>, prevState: Readonly<SettingsState>, snapshot?: any): void {
    if (prevProps.server !== this.props.server) {
      this.props.loadServerSettings(this.props.server.uuid);
    }
  }

  render = () => {
    const { server } = this.props;
    if (!server) {
      return null;
    }

    return (
        <Page>
            <Box sx={{ p: 5 }}>
                <CreatorModes server={server} />
                <Box sx={{ typography: 'h6', mt: '1rem' }}>Respawn Settings:</Box>
                <RespawnLocation server={server} />
                <RespawnGrowth server={server} />
            </Box>
        </Page>
    );
  }
}


const mapStateToProps = ({ servers  }: ReduxState) => ({
  server: servers?.serverDetail?.server,
});

export const ServerSettingsTab =  withSmallScreenCheck(connect(mapStateToProps, {loadServerSettings})(SettingsComponent));