import axios from 'axios';
import { CommandResponse } from "./server-commands.types";
import { FriendlyApiError, GenericError } from './types';

const SERVER_ENDPOINT = `/api/server`
const SEND_COMMAND_ENDPOINT = `commands/send`
const RESTART_ENDPOINT = `commands/restart`

const TIME_ENDPOINT_ENDPOINT = `commands/time`
const WEATHER_ENDPOINT_ENDPOINT = `commands/weather`
const CLEAR_BODIES_ENDPOINT = `commands/clear-bodies`
const ANNOUNCE_ENDPOINT = `commands/announce`
const CANCEL_RESTART_ENDPOINT = `commands/cancel-restart`


export const sendCommand = async(serverUuid: string, command: string): Promise<CommandResponse> => {
    try {

        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${SEND_COMMAND_ENDPOINT}`, { command });

        return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw new GenericError('Failed to send command');
    }
}

export const sendRestartCommand = async(serverUuid: string, seconds: number): Promise<CommandResponse> => {
    try {

        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${RESTART_ENDPOINT}`, { seconds });

        return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw new GenericError('Failed to send restart command');
    }
}

export const sendCancelRestart = async(serverUuid: string): Promise<CommandResponse> => {
    try {

        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${CANCEL_RESTART_ENDPOINT}`);

        return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw new GenericError('Failed to send cancel restart command');
    }
}

export const sendTimeCommand = async(serverUuid: string, time: string): Promise<CommandResponse> => {
    try {

        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${TIME_ENDPOINT_ENDPOINT}`, { time });

        return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw new GenericError('Failed to send time command');
    }
}

export const sendWeatherCommand = async(serverUuid: string, weather: string): Promise<CommandResponse> => {
    try {

        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${WEATHER_ENDPOINT_ENDPOINT}`, { weather });

        return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw new GenericError('Failed to send weather command');
    }
}

export const sendClearBodiesCommand = async(serverUuid: string): Promise<CommandResponse> => {
    try {

        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${CLEAR_BODIES_ENDPOINT}`);

        return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw new GenericError('Failed to send clear bodies command');
    }
}

export const sendAnnounceCommand = async(serverUuid: string, message: string): Promise<CommandResponse> => {
    try {

        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${ANNOUNCE_ENDPOINT}`, { message });

        return data;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw new GenericError('Failed to send announce command');
    }
}