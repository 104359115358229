
export enum PATH_PERMISSION {
  ANNOUNCE = 'pot.announce',
  BAN = 'pot.ban',
  CLEARBODIES = 'pot.clearbodies',
  CLEARCREATOROBJECTS = 'pot.clearcreatorobjects',
  DELWHITELIST = 'pot.delwhitelist',
  GET_ATTRIBUTE = 'pot.get_attribute',
  GIVEQUEST = 'pot.givequest',
  GODMODE = 'pot.godmode',
  HEAL = 'pot.heal',
  HEALALL = 'pot.healall',
  KICK = 'pot.kick',
  LISTCREATORMODE = 'pot.listcreatormode',
  LISTPOI = 'pot.listpoi',
  LISTQUESTS = 'pot.listquests',
  LISTROLES = 'pot.listroles',
  LISTWATERS = 'pot.listwaters',
  LISTWAYSTONES = 'pot.listwaystones',
  LOAD = 'pot.load',
  LOADCREATORMODE = 'pot.loadcreatormode',
  MODIFY_ATTRIBUTE = 'pot.modify_attribute',
  PLAYERINFO = 'pot.playerinfo',
  PROMOTE = 'pot.promote',
  RELOADBANS = 'pot.reloadbans',
  RELOADMOTD = 'pot.reloadmotd',
  RELOADMUTES = 'pot.reloadmutes',
  RELOADRULES = 'pot.reloadrules',
  RELOADWHITELIST = 'pot.reloadwhitelist',
  REMOVECREATORMODE = 'pot.removecreatormode',
  RESETCREATORMODE = 'pot.resetcreatormode',
  RESTART = 'pot.restart',
  SAVE = 'pot.save',
  SAVECREATORMODE = 'pot.savecreatormode',
  SERVERMUTE = 'pot.servermute',
  SERVERUNMUTE = 'pot.serverunmute',
  SET_ATTRIBUTE = 'pot.set_attribute',
  SET_ATTRIBUTE_ALL = 'pot.set_attribute_all',
  SETMARKS = 'pot.setmarks',
  SETMARKSALL = 'pot.setmarksall',
  SKIPSHED = 'pot.skipshed',
  TELEPORT = 'pot.teleport',
  TELEPORTALL = 'pot.teleportall',
  TIME_OF_DAY = 'pot.time_of_day',
  UNBAN = 'pot.unban',
  WATERQUALITY = 'pot.waterquality',
  WAYSTONECOOLDOWN = 'pot.waystonecooldown',
  WEATHER = 'pot.weather',
  WHITELIST = 'pot.whitelist',
  WHISPER_ALL = 'pot.whisperAll',
  WHISPER = 'pot.whisper',
}

export enum INTERNAL_PERMISSION {
  HOME_TAB = 'titansdash.home_tab',
  COMMANDS_TAB = 'titansdash.commands_tab',
  LOCATIONS_TAB = 'titansdash.locations_tab',
  SETTINGS_TAB = 'titansdash.settings_tab',
  ROLES_TAB = 'titansdash.roles_tab',
  MEMBERS_TAB = 'titansdash.members_tab',
  ACTIVITY_LOGS_TAB = 'titansdash.activity_logs_tab',
  SERVER_CONFIG_TAB = 'titansdash.server_config_tab',
  CONNECTIVITY_TAB = 'titansdash.connectivity_tab',
  STATUS_TAB = 'titansdash.status_tab',
  TOURNAMENTS = 'titansdash.tournaments',
  GROW = 'titansdash.grow',
  GROW_ALL = 'titansdash.grow_all',
  SET_STAMINA = 'titansdash.set_stamina',
  SET_STAMINA_ALL = 'titansdash.set_stamina_all',
  SEND_RAW_COMMAND = 'titansdash.send_raw_command',
  KILL_ALL = 'titansdash.kill_all',
  KILL = 'titansdash.kill',
  PREP_ALL = 'titansdash.prep_all',
  PREP = 'titansdash.prep',
  LIST_PLAYERS = 'titansdash.list_players',
  MAX_HEALTH = 'titansdash.max_health',
  MAX_STAMINA = 'titansdash.max_stamina',
  MAX_MOVEMENT = 'titansdash.max_movement',
  MAX_SPRINTING = 'titansdash.max_sprinting',
  MAX_TROTTING = 'titansdash.max_trotting',
  MAX_COMBAT = 'titansdash.max_combat',
  MANAGE_DETAILS = 'titansdash.manage_details',
  MANAGE_RCON = 'titansdash.manage_rcon',
  MANAGE_LOCATIONS = 'titansdash.manage_locations',
  MANAGE_SETTINGS = 'titansdash.manage_settings',
  MANAGE_MEMBERS = 'titansdash.manage_members',
  MANAGE_ROLES = 'titansdash.manage_roles',
  MANAGE_INVITES = 'titansdash.manage_invites',
  MANAGE_WEBHOOKS = 'titansdash.manage_webhooks',
  MANAGE_INI = 'titansdash.manage_ini',
  MANAGE_INI_CLI_TOOL = 'titansdash.manage_ini_cli_access',
  READ_SERVER_LOGINS = 'titansdash.read_server_logins',
  READ_RCON = 'titansdash.read_rcon',
  READ_DETAILS = 'titansdash.read_details',
  READ_LOCATIONS = 'titansdash.read_locations',
  READ_SETTINGS = 'titansdash.read_settings',
  READ_MEMBERS = 'titansdash.read_members',
  READ_ROLES = 'titansdash.read_roles',
  READ_INVITES = 'titansdash.read_invites',
  READ_WEBHOOKS = 'titansdash.read_webhooks',
  READ_INI = 'titansdash.read_ini',
  READ_SERVER_REGISTRATION = 'titansdash.read_server_registration',
  MANAGE_SERVER = 'titansdash.manage_server',
  DELETE_SERVER = 'titansdash.delete_server',
  MANAGER_PLAYER = 'titansdash.manager_player',
  MANAGER_ALL_PLAYERS = 'titansdash.manager_all_players',
  MANAGE_TIME_OF_DAY = 'titansdash.manage_time_of_day',
  MANAGE_WEATHER = 'titansdash.manage_weather',
  USE_RESERVED_LOCATIONS = 'titansdash.use_reserved_locations',
  MANAGE_OWN_ROLES = 'titansdash.manage_own_roles',
  CAN_ASSIGN_HIGHER_PERMISSIONS = 'titansdash.can_assign_higher_permissions',
  TEST = 'titansdash.test',
}

export const PERMISSION_LABELS: { [key: string]: string } = {
  [PATH_PERMISSION.ANNOUNCE]: 'Announce',
  [PATH_PERMISSION.BAN]: 'Ban',
  [PATH_PERMISSION.CLEARBODIES]: 'Clear Bodies',
  [PATH_PERMISSION.CLEARCREATOROBJECTS]: 'Clear Creator Objects',
  [PATH_PERMISSION.DELWHITELIST]: 'Remove Whitelist',
  [PATH_PERMISSION.GET_ATTRIBUTE]: 'Get Attribute',
  [PATH_PERMISSION.GIVEQUEST]: 'Give Quest',
  [PATH_PERMISSION.GODMODE]: 'God Mode',
  [PATH_PERMISSION.HEAL]: 'Heal',
  [PATH_PERMISSION.HEALALL]: 'Heal All',
  [PATH_PERMISSION.KICK]: 'Kick',
  [PATH_PERMISSION.LISTCREATORMODE]: 'List Creator Mode',
  [PATH_PERMISSION.LISTPOI]: 'List POI',
  [PATH_PERMISSION.LISTQUESTS]: 'List Quests',
  [PATH_PERMISSION.LISTROLES]: 'List Roles',
  [PATH_PERMISSION.LISTWATERS]: 'List Waters',
  [PATH_PERMISSION.LISTWAYSTONES]: 'List Waystones',
  [PATH_PERMISSION.LOAD]: 'Load',
  [PATH_PERMISSION.LOADCREATORMODE]: 'Load Creator Mode',
  [PATH_PERMISSION.MODIFY_ATTRIBUTE]: 'Modify Attribute',
  [PATH_PERMISSION.PLAYERINFO]: 'Player Info',
  [PATH_PERMISSION.PROMOTE]: 'Promote',
  [PATH_PERMISSION.RELOADBANS]: 'Reload Bans',
  [PATH_PERMISSION.RELOADMOTD]: 'Reload MOTD',
  [PATH_PERMISSION.RELOADMUTES]: 'Reload Mutes',
  [PATH_PERMISSION.RELOADRULES]: 'Reload Rules',
  [PATH_PERMISSION.RELOADWHITELIST]: 'Reload Whitelist',
  [PATH_PERMISSION.REMOVECREATORMODE]: 'Remove Creator Mode',
  [PATH_PERMISSION.RESETCREATORMODE]: 'Reset Creator Mode',
  [PATH_PERMISSION.RESTART]: 'Restart',
  [PATH_PERMISSION.SAVE]: 'Save',
  [PATH_PERMISSION.SAVECREATORMODE]: 'Save Creator Mode',
  [PATH_PERMISSION.SERVERMUTE]: 'Server Mute',
  [PATH_PERMISSION.SERVERUNMUTE]: 'Server Unmute',
  [PATH_PERMISSION.SET_ATTRIBUTE]: 'Set Attribute',
  [PATH_PERMISSION.SET_ATTRIBUTE_ALL]: 'Set Attribute All',
  [PATH_PERMISSION.SETMARKS]: 'Set Marks',
  [PATH_PERMISSION.SETMARKSALL]: 'Set Marks All',
  [PATH_PERMISSION.SKIPSHED]: 'Skip Shed',
  [PATH_PERMISSION.TELEPORT]: 'Teleport',
  [PATH_PERMISSION.TELEPORTALL]: 'Teleport All',
  [PATH_PERMISSION.TIME_OF_DAY]: 'Time of Day',
  [PATH_PERMISSION.UNBAN]: 'Unban',
  [PATH_PERMISSION.WATERQUALITY]: 'Water Quality',
  [PATH_PERMISSION.WAYSTONECOOLDOWN]: 'Waystone Cooldown',
  [PATH_PERMISSION.WEATHER]: 'Weather',
  [PATH_PERMISSION.WHITELIST]: 'Whitelist',
  [PATH_PERMISSION.WHISPER_ALL]: 'Whisper All',
  [PATH_PERMISSION.WHISPER]: 'Whisper',
  [INTERNAL_PERMISSION.HOME_TAB]: 'Home',
  [INTERNAL_PERMISSION.COMMANDS_TAB]: 'Commands',
  [INTERNAL_PERMISSION.LOCATIONS_TAB]: 'Locations',
  [INTERNAL_PERMISSION.SETTINGS_TAB]: 'Settings',
  [INTERNAL_PERMISSION.ROLES_TAB]: 'Roles (Site Only)',
  [INTERNAL_PERMISSION.MEMBERS_TAB]: 'Members',
  [INTERNAL_PERMISSION.ACTIVITY_LOGS_TAB]: 'Activity Logs',
  [INTERNAL_PERMISSION.SERVER_CONFIG_TAB]: 'Server Config',
  [INTERNAL_PERMISSION.STATUS_TAB]: 'Status',
  [INTERNAL_PERMISSION.TOURNAMENTS]: 'Tournaments',
  [INTERNAL_PERMISSION.GROW]: 'Grow',
  [INTERNAL_PERMISSION.GROW_ALL]: 'Grow All',
  [INTERNAL_PERMISSION.SET_STAMINA]: 'Set Stamina',
  [INTERNAL_PERMISSION.SET_STAMINA_ALL]: 'Set Stamina All',
  [INTERNAL_PERMISSION.SEND_RAW_COMMAND]: 'Send Raw Command',
  [INTERNAL_PERMISSION.KILL_ALL]: 'Kill All',
  [INTERNAL_PERMISSION.KILL]: 'Kill',
  [INTERNAL_PERMISSION.PREP_ALL]: 'Prep All',
  [INTERNAL_PERMISSION.PREP]: 'Prep',
  [INTERNAL_PERMISSION.LIST_PLAYERS]: 'List Players',
  [INTERNAL_PERMISSION.MAX_HEALTH]: 'Max Health',
  [INTERNAL_PERMISSION.MAX_STAMINA]: 'Max Stamina',
  [INTERNAL_PERMISSION.MAX_MOVEMENT]: 'Max Movement',
  [INTERNAL_PERMISSION.MAX_SPRINTING]: 'Max Sprinting',
  [INTERNAL_PERMISSION.MAX_TROTTING]: 'Max Trotting',
  [INTERNAL_PERMISSION.MAX_COMBAT]: 'Max Combat',
  [INTERNAL_PERMISSION.MANAGE_DETAILS]: 'Manage Details',
  [INTERNAL_PERMISSION.MANAGE_RCON]: 'Manage RCON',
  [INTERNAL_PERMISSION.MANAGE_LOCATIONS]: 'Manage Locations',
  [INTERNAL_PERMISSION.MANAGE_SETTINGS]: 'Manage Settings',
  [INTERNAL_PERMISSION.MANAGE_MEMBERS]: 'Manage Members',
  [INTERNAL_PERMISSION.MANAGE_ROLES]: 'Manage Roles',
  [INTERNAL_PERMISSION.MANAGE_INVITES]: 'Manage Invites',
  [INTERNAL_PERMISSION.MANAGE_WEBHOOKS]: 'Manage Webhooks',
  [INTERNAL_PERMISSION.MANAGE_INI]: 'Manage INI',
  [INTERNAL_PERMISSION.MANAGE_INI_CLI_TOOL]: 'Manage INI CLI Tool',
  [INTERNAL_PERMISSION.READ_SERVER_LOGINS]: 'Read Server Logins',
  [INTERNAL_PERMISSION.READ_RCON]: 'Read RCON',
  [INTERNAL_PERMISSION.READ_DETAILS]: 'Read Details',
  [INTERNAL_PERMISSION.READ_LOCATIONS]: 'Read Locations',
  [INTERNAL_PERMISSION.READ_SETTINGS]: 'Read Settings',
  [INTERNAL_PERMISSION.READ_MEMBERS]: 'Read Members',
  [INTERNAL_PERMISSION.READ_ROLES]: 'Read Roles',
  [INTERNAL_PERMISSION.READ_INVITES]: 'Read Invites',
  [INTERNAL_PERMISSION.READ_WEBHOOKS]: 'Read Webhooks',
  [INTERNAL_PERMISSION.READ_INI]: 'Read INI',
  [INTERNAL_PERMISSION.MANAGER_PLAYER]: 'Manager Player',
  [INTERNAL_PERMISSION.MANAGER_ALL_PLAYERS]: 'Manager All Players',
  [INTERNAL_PERMISSION.MANAGE_TIME_OF_DAY]: 'Manage Time of Day',
  [INTERNAL_PERMISSION.MANAGE_WEATHER]: 'Manage Weather',
  [INTERNAL_PERMISSION.USE_RESERVED_LOCATIONS]: 'Use Reserved Locations',
  [INTERNAL_PERMISSION.MANAGE_OWN_ROLES]: 'Manage Own Roles',
  [INTERNAL_PERMISSION.CAN_ASSIGN_HIGHER_PERMISSIONS]: 'Can Assign Higher Permissions',
}

export interface PermissionSection {
  title: string;
  permissions: (PATH_PERMISSION | INTERNAL_PERMISSION)[];
  requires?: PATH_PERMISSION | INTERNAL_PERMISSION
}

export interface PermissionGroup extends PermissionSection {
  sections?: PermissionSection[];
}
export const TAB_PERMISSION_LISTINGS: { [key: string]: PermissionGroup } = {
  home: {
    title: 'Home',
    permissions:[],
    sections: [
      {
        title: 'Manage Player',
        permissions: [
          INTERNAL_PERMISSION.LIST_PLAYERS,
          PATH_PERMISSION.PLAYERINFO,
          INTERNAL_PERMISSION.PREP,
          INTERNAL_PERMISSION.GROW,
          PATH_PERMISSION.SETMARKS,
          PATH_PERMISSION.HEAL,
          PATH_PERMISSION.TELEPORT,
          INTERNAL_PERMISSION.USE_RESERVED_LOCATIONS,
          INTERNAL_PERMISSION.MANAGER_ALL_PLAYERS
        ],
      },
      {
        title: 'Hacks',
        permissions: [
          INTERNAL_PERMISSION.MAX_HEALTH,
          INTERNAL_PERMISSION.MAX_STAMINA,
          INTERNAL_PERMISSION.MAX_MOVEMENT,
          INTERNAL_PERMISSION.MAX_SPRINTING,
          INTERNAL_PERMISSION.MAX_TROTTING,
          INTERNAL_PERMISSION.MAX_COMBAT,
          PATH_PERMISSION.GODMODE,
          INTERNAL_PERMISSION.KILL,
          PATH_PERMISSION.SKIPSHED,
        ]
      },
      {
        title: 'Moderate Player',
        permissions: [
          PATH_PERMISSION.KICK,
          PATH_PERMISSION.BAN,
          PATH_PERMISSION.UNBAN,
          PATH_PERMISSION.SERVERMUTE,
          PATH_PERMISSION.SERVERUNMUTE,
        ]
      },
      {
        title: 'Server Actions',
        permissions: [
          INTERNAL_PERMISSION.PREP_ALL,
          INTERNAL_PERMISSION.GROW_ALL,
          PATH_PERMISSION.SETMARKSALL,
          PATH_PERMISSION.HEALALL,
          PATH_PERMISSION.TELEPORTALL,
          PATH_PERMISSION.WEATHER,
          PATH_PERMISSION.TIME_OF_DAY,
          PATH_PERMISSION.ANNOUNCE,
          PATH_PERMISSION.CLEARBODIES,
          PATH_PERMISSION.RESTART,
          INTERNAL_PERMISSION.SEND_RAW_COMMAND,
        ]
      },
    ],
    requires: INTERNAL_PERMISSION.HOME_TAB,
  },
  admin_tabs: {
    title: 'Admin Tabs',
    permissions: [
      INTERNAL_PERMISSION.COMMANDS_TAB,
      INTERNAL_PERMISSION.LOCATIONS_TAB,
      INTERNAL_PERMISSION.SETTINGS_TAB,
      INTERNAL_PERMISSION.ROLES_TAB,
      INTERNAL_PERMISSION.MEMBERS_TAB,
      INTERNAL_PERMISSION.LOGINS_TAB,
      INTERNAL_PERMISSION.SERVER_CONFIG_TAB,
      INTERNAL_PERMISSION.STATUS_TAB,
    ],
  },
  commands: {
    title: 'Server Commands',
    permissions: [INTERNAL_PERMISSION.SEND_RAW_COMMAND],
    requires: INTERNAL_PERMISSION.COMMANDS_TAB,
  },
  locations: {
    title: 'Locations',
    permissions: [
      INTERNAL_PERMISSION.MANAGE_LOCATIONS,
      INTERNAL_PERMISSION.READ_LOCATIONS,
    ],
    requires: INTERNAL_PERMISSION.LOCATIONS_TAB,
  },
  settings: {
    title: 'Settings Tab',
    permissions: [
      INTERNAL_PERMISSION.MANAGE_SETTINGS,
      INTERNAL_PERMISSION.READ_SETTINGS,
      INTERNAL_PERMISSION.MANAGE_WEBHOOKS,
      INTERNAL_PERMISSION.READ_WEBHOOKS,
    ],
    requires: INTERNAL_PERMISSION.SETTINGS_TAB,
  },
  roles: {
    title: 'Roles (Site Only)',
    permissions: [
      INTERNAL_PERMISSION.MANAGE_ROLES,
      INTERNAL_PERMISSION.READ_ROLES,
    ],
    requires: INTERNAL_PERMISSION.ROLES_TAB,
  },
  members: {
    title: 'Members (Site Only)',
    permissions: [
      INTERNAL_PERMISSION.MANAGE_MEMBERS,
      INTERNAL_PERMISSION.READ_MEMBERS,
      INTERNAL_PERMISSION.MANAGE_INVITES,
      INTERNAL_PERMISSION.READ_INVITES,
    ],
    requires: INTERNAL_PERMISSION.MEMBERS_TAB,
  },
  logins: {
    title: 'Server Logins',
    permissions: [
      INTERNAL_PERMISSION.READ_SERVER_LOGINS,
    ],
    requires: INTERNAL_PERMISSION.LOGINS_TAB,
  },
  configs: {
    title: 'Server Configs',
    permissions: [
      INTERNAL_PERMISSION.MANAGE_INI,
      INTERNAL_PERMISSION.READ_INI,
      INTERNAL_PERMISSION.MANAGE_INI_CLI_TOOL,
    ],
    requires: INTERNAL_PERMISSION.SERVER_CONFIG_TAB,
  },
  status: {
    title: 'Status',
    permissions: [
      INTERNAL_PERMISSION.MANAGE_DETAILS,
      INTERNAL_PERMISSION.READ_DETAILS,
      INTERNAL_PERMISSION.MANAGE_RCON,
      INTERNAL_PERMISSION.READ_RCON,
      INTERNAL_PERMISSION.READ_SERVER_REGISTRATION,
    ],
    requires: INTERNAL_PERMISSION.STATUS_TAB,
  },
}

export const ALL_PERMISSION_LISTINGS: { [key: string]: PermissionGroup } = {
  management: {
    title: 'Dashboard Management',
    permissions: [
      INTERNAL_PERMISSION.HOME_TAB,
      INTERNAL_PERMISSION.COMMANDS_TAB,
      INTERNAL_PERMISSION.LOCATIONS_TAB,
      INTERNAL_PERMISSION.SETTINGS_TAB,
      INTERNAL_PERMISSION.ROLES_TAB,
      INTERNAL_PERMISSION.MEMBERS_TAB,
      INTERNAL_PERMISSION.LOGINS_TAB,
      INTERNAL_PERMISSION.SERVER_CONFIG_TAB,
      INTERNAL_PERMISSION.STATUS_TAB,
      INTERNAL_PERMISSION.MANAGE_DETAILS,
      INTERNAL_PERMISSION.READ_DETAILS,
      INTERNAL_PERMISSION.MANAGE_RCON,
      INTERNAL_PERMISSION.READ_RCON,
      INTERNAL_PERMISSION.MANAGE_LOCATIONS,
      INTERNAL_PERMISSION.READ_LOCATIONS,
      INTERNAL_PERMISSION.MANAGE_SETTINGS,
      INTERNAL_PERMISSION.READ_SETTINGS,
      INTERNAL_PERMISSION.MANAGE_MEMBERS,
      INTERNAL_PERMISSION.READ_MEMBERS,
      INTERNAL_PERMISSION.MANAGE_ROLES,
      INTERNAL_PERMISSION.READ_ROLES,
      INTERNAL_PERMISSION.MANAGE_INVITES,
      INTERNAL_PERMISSION.READ_INVITES,
      INTERNAL_PERMISSION.MANAGE_WEBHOOKS,
      INTERNAL_PERMISSION.READ_WEBHOOKS,
      INTERNAL_PERMISSION.MANAGE_INI,
      INTERNAL_PERMISSION.READ_INI,
      INTERNAL_PERMISSION.READ_SERVER_LOGINS,
      INTERNAL_PERMISSION.MANAGE_INI_CLI_TOOL,
      INTERNAL_PERMISSION.USE_RESERVED_LOCATIONS,
      INTERNAL_PERMISSION.SEND_RAW_COMMAND,
    ],
  },
  players_all: {
    title: 'Player Commands (All)',
    permissions: [
      INTERNAL_PERMISSION.PREP_ALL,
      INTERNAL_PERMISSION.GROW_ALL,
      PATH_PERMISSION.SETMARKSALL,
      PATH_PERMISSION.HEALALL,
      INTERNAL_PERMISSION.KILL_ALL,
      INTERNAL_PERMISSION.SET_STAMINA_ALL,
      PATH_PERMISSION.SET_ATTRIBUTE_ALL,
      PATH_PERMISSION.TELEPORTALL,
      PATH_PERMISSION.WHISPER_ALL,
    ],
  },
  players: {
    title: 'Player Commands (Individual)',
    permissions: [
      INTERNAL_PERMISSION.PREP,
      INTERNAL_PERMISSION.GROW,
      PATH_PERMISSION.SETMARKS,
      PATH_PERMISSION.HEAL,
      INTERNAL_PERMISSION.KILL,
      PATH_PERMISSION.GET_ATTRIBUTE,
      PATH_PERMISSION.MODIFY_ATTRIBUTE,
      PATH_PERMISSION.SET_ATTRIBUTE,
      INTERNAL_PERMISSION.SET_STAMINA,
      PATH_PERMISSION.TELEPORT,
      PATH_PERMISSION.SKIPSHED,
      PATH_PERMISSION.GODMODE,
      PATH_PERMISSION.PLAYERINFO,
    ],
  },
  server_commands_players: {
    title: 'Player Managment',
    permissions: [
      PATH_PERMISSION.KICK,
      PATH_PERMISSION.BAN,
      PATH_PERMISSION.UNBAN,
      PATH_PERMISSION.SERVERMUTE,
      PATH_PERMISSION.SERVERUNMUTE,
      PATH_PERMISSION.WHITELIST,
      PATH_PERMISSION.DELWHITELIST,
      PATH_PERMISSION.PROMOTE,
      PATH_PERMISSION.GIVEQUEST,
    ],
  },
  server_commands_list: {
    title: 'Server Commands (List)',
    permissions: [
      PATH_PERMISSION.LISTPOI,
      PATH_PERMISSION.LISTQUESTS,
      PATH_PERMISSION.LISTROLES,
      PATH_PERMISSION.LISTWATERS,
      PATH_PERMISSION.LISTWAYSTONES,
    ],
  },
  server_commands_creator: {
    title: 'Server Commands (Creator)',
    permissions: [
      PATH_PERMISSION.LISTCREATORMODE,
      PATH_PERMISSION.LOADCREATORMODE,
      PATH_PERMISSION.REMOVECREATORMODE,
      PATH_PERMISSION.RESETCREATORMODE,
      PATH_PERMISSION.SAVECREATORMODE,
      PATH_PERMISSION.CLEARCREATOROBJECTS,
    ],
  },
  server_commands_reload: {
    title: 'Server Commands (Configuration)',
    permissions: [
      PATH_PERMISSION.LOAD,
      PATH_PERMISSION.SAVE,
      PATH_PERMISSION.RELOADBANS,
      PATH_PERMISSION.RELOADMOTD,
      PATH_PERMISSION.RELOADMUTES,
      PATH_PERMISSION.RELOADRULES,
    ],
  },
  server_commands_misc: {
    title: 'Server Commands (Misc)',
    permissions: [
      PATH_PERMISSION.ANNOUNCE,
      PATH_PERMISSION.CLEARBODIES,
      PATH_PERMISSION.RESTART,
      PATH_PERMISSION.TIME_OF_DAY,
      PATH_PERMISSION.WATERQUALITY,
      PATH_PERMISSION.WAYSTONECOOLDOWN,
      PATH_PERMISSION.WEATHER,
    ],
  },
}


export interface ServerRole {
    uuid: string;
    server_uuid: string;
    name: string;
    permissions: string[];
    is_default: boolean;
}

export const STANDARD_MEMBER_ROLES = [
    INTERNAL_PERMISSION.HOME_TAB,
    INTERNAL_PERMISSION.LIST_PLAYERS,
    PATH_PERMISSION.PLAYERINFO,
    PATH_PERMISSION.LISTPOI,
    INTERNAL_PERMISSION.READ_LOCATIONS,
]

export const ADMIN_TABS = [
  INTERNAL_PERMISSION.COMMANDS_TAB,
  INTERNAL_PERMISSION.LOCATIONS_TAB,
  INTERNAL_PERMISSION.SETTINGS_TAB,
  INTERNAL_PERMISSION.ROLES_TAB,
  INTERNAL_PERMISSION.MEMBERS_TAB,
  INTERNAL_PERMISSION.LOGINS_TAB,
  INTERNAL_PERMISSION.SERVER_CONFIG_TAB,
  INTERNAL_PERMISSION.STATUS_TAB,
]