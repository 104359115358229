import React from 'react';
import { connect } from 'react-redux';
import { withAuthProps } from '../../../common/hooks/withAuth';
import { ReduxState } from '../../../reducers';
import { withRouter, WithRouterProps } from '../../../common/hooks/withParams';
import { ServerDetailsModel } from '../../../api/server.types';
import { Page } from '../../common/Page';
import { deleteServer } from '../../../actions/server';
import { Box, Button, TextField, Typography } from '@mui/material';
import { ConfirmationDialog } from '../../../components/common/Modal';
import { addError } from '../../../actions/notifications';

interface ReduxStateProps {
  serverDetails?: ServerDetailsModel;
}

interface ReduxActionProps {
  deleteServer: (serverUuid: string) => void;
  addError: (message: string) => void;
 }

interface ComponentProps {}

interface ComponentState {
  showDeleteDialog: boolean;
  confirmText: string;
}

export type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps & withAuthProps & WithRouterProps;

class DeleteServer extends React.Component<CompositeProps, ComponentState> {
  state: ComponentState = {
    showDeleteDialog: false,
    confirmText: '',
  };
  onShowConfirmDelete = () => {
    this.setState({ showDeleteDialog: true, confirmText: '' });
  }

  onCloseDeleteDialog = () => { 
    this.setState({ showDeleteDialog: false, confirmText: '' });
  }

  onConfirmDelete = () => {
    if (this.state.confirmText !== 'delete server') {
      this.props.addError('You must type "delete server" to confirm deletion of the server');
      return;
    }
    const { serverDetails } = this.props;
    if (!serverDetails) {
      return;
    }
    this.props.deleteServer(serverDetails.server.uuid);
  }

  onChangeConfirmText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmText: event.target.value });
  }
  
  render() {
    const { serverDetails } = this.props;
    if (!serverDetails) {
      return null;
    }
    return (
      <Page>
        <ConfirmationDialog
          id={'delete-server'}
          title={'Delete Server'}
          visible={this.state.showDeleteDialog}
          onClose={this.onCloseDeleteDialog}
          onConfirmed={this.onConfirmDelete}
          okButtonText={'Delete Server'}>
          <Typography>Type <Typography component={'span'} sx={{ fontStyle: 'italic'}}>delete server</Typography> to confirm deletion</Typography>
          <TextField
              sx={{ mt: 1, width: '100%' }}
              type="text"
              value={this.state.confirmText}
              onChange={this.onChangeConfirmText} 
          />
        </ConfirmationDialog>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Typography sx={{
            mb: 2,
            fontSize: '1rem',
            color: 'red',
          }}>{'Are you sure you want to delete this server? All your hard-work will be lost'}</Typography>
          <Typography sx={{
            fontSize: '1.5rem',
            color: 'red',
          }}>{'This action is irreversible!'}</Typography>
          <Button
            sx={{ mt: 5 }}
            variant="outlined"
            color="primary"
            onClick={this.onShowConfirmDelete} 
          >
            {'Delete Server'}
          </Button>
        </Box>
      </Page>
    )
  }
}


const mapStateToProps = ({ servers }: ReduxState) => {
  return {
    serverDetails: servers.serverDetail,
    loading: servers.loadingServerDetails,
  }
};

export const DeleteServerTab = withRouter(connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, { deleteServer, addError })(DeleteServer));