import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import { Box, SxProps, Typography } from '@mui/material';
import { useState } from 'react';
import { ServerModel } from '../../../api/server.types';

export interface EditRCONDialogProps {
  sx?: SxProps;
  contentSx?: SxProps;
  visible: boolean;
  onClose: () => void;
  onConfirmed: (connection: { host: string, port: number, password: string }) => void;
}

export const EditRCONDialog = (props: EditRCONDialogProps) => {
  const { onClose, onConfirmed, visible, ...other } = props;
  
  const [host, setHost] = useState<string>('');
  const [port, setPort] = useState<number | null>(null);
  const [password, setPassword] = useState<string>('');

  const [hostError, setHostError] = useState<boolean>(false);
  const [portError, setPortError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  React.useEffect(() => {
    setHost('');
    setPort(null);
    setPassword('');
  }, [visible]);

  const handelOnClose = () => {
    onClose();
    setHost('');
    setPort(0);
    setPassword('');
  }

  const onHostHandler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setHost(e.target.value);
  }, [setHost]);

  const onPortHandler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setPort(null);
      return;
    }

    if (isNaN(Number(e.target.value))) {
      return
    }

    setPort(Number(e.target.value));
  }, [setPort]);

  const onPasswordHandler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, [setPassword]);

  const onSaveHandler = React.useCallback(() => {
    let errors = [];
    const ipAddressValidation = new RegExp('^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$');
    if (!host || !ipAddressValidation.test(host)) {
      setHostError(true);
      errors.push('A valid ip address is required');
    } else {
      setHostError(false);
    }

    const portValidation = new RegExp('^[0-9]{1,5}$');
    if (!port || !portValidation.test(port.toString())) {
      setPortError(true);
      errors.push('A valid port is required');
    } else {
      setPortError(false);
    }
    if (!password) {
      setPasswordError(true);
      errors.push('A password is required');
    } else {
      setPasswordError(false);
    }
    if (errors.length) {
      setErrorMessages(errors);
      return;
    }
    onConfirmed({ host, port: port || 0, password });
    setHost('');
    setPort(0);
    setPassword('');
  }, [host, port, password, onConfirmed]);

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '60rem' }, ...(props.sx ?? {}) }}
      maxWidth="xs"
      open={visible}
      {...other}
    >
      <DialogTitle>{'RCON Configuration'}</DialogTitle>
      <DialogContent sx={props.contentSx} dividers>
        <TextField
          label="IP Address"
          fullWidth
          margin="normal"
          value={host}
          onChange={onHostHandler}
          error={!!hostError}
        />
        <TextField
          type='number'
          label="Port"
          fullWidth
          margin="normal"
          value={port}
          onChange={onPortHandler}
          error={!!portError}
        />
        <TextField
          type="password"
          label="Password"
          fullWidth
          margin="normal"
          value={password}
          error={!!passwordError}
          onChange={onPasswordHandler}
        />
        {!!errorMessages.length && (
          <Box sx={{ color: 'red'}}>
            {errorMessages.map((error, index) => (
              <Typography key={index}>{error}</Typography>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ height: '5rem'}}>
        <Button onClick={onSaveHandler}>{'Save'}</Button>
        <Button autoFocus onClick={handelOnClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
