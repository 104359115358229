/*eslint import/namespace: [2, { allowComputed: true }]*/

import React from 'react';
import axios from 'axios'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { Home } from './components/home';
import { LoginComponent } from './components/auth/Login';
import { Header } from './components/nav/Header';
import { Box } from '@mui/material';
import { RequiresAuthentication } from './components/common/RequiresAuthentication';
import UserSync from './components/misc/UserSync';
import { RegisterComponent } from './components/auth/Register';
import NotificationBar from './components/common/NotificationBar';
import { useAuth } from './common/hooks/useAuth';
import ForgotPassword from './components/forgot-password/ForgotPassword';
import ResetPassword from './components/forgot-password/ResetPassword';
import NotFound from './components/common/Errors/NotFound';
import { AccountComponent } from './components/account';
import { ServerConnectivityTab, ServerLocationsTab, ServerPlayersTab, ServerSettingsTab, ServerEditorTab, ServerHomeComponent } from './components/servers/server';
import { ServerCommandsTab } from './components/servers/commands';
import { ServerMembersTab } from './components/servers/members/ServerMembersTab';
import { ServerInvitationComponent } from './components/invitation/ServerInvitation';
import RequiresPermission from './components/common/RequiresPermission';
import RequiresScope from './components/common/RequiresScope';
import { INTERNAL_PERMISSION } from './api/server-roles.types';
import RolesComponent from './components/servers/roles/RolesComponent';
import { DiscordAuthComponent } from './components/auth/DiscordAuth';
import ServerLoginsTab from './components/servers/logins/ServerLoginsTab';
import { AdminComponent } from './components/servers/admin/Admin';
import { PotLogs, ServiceLogs } from './components/admin/Debug';
import WebhooksComponent from './components/servers/settings/WebhooksComponent';
import { ActivityLogsTab } from './components/servers/logs/ActivityLogsTab';
import { DeleteServerTab } from './components/servers/delete';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const App: React.FunctionComponent = () => {
  const auth = useAuth();
  const { user } = auth;
  const hasAdmin = ['titandash/root', 'titandash/admin'].some((role) => user?.scope?.includes(role));
  return (
      <Router>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
              <NotificationBar />
              <Header />
              <UserSync />
              <Box>
                <Routes>
                  <Route path="/login" element={<LoginComponent />} />
                  <Route path="/register" element={<RegisterComponent />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/auth/discord" element={<DiscordAuthComponent />} />
                  <Route path="/" element={<RequiresAuthentication><Home /></RequiresAuthentication>} />
                  <Route path="/account" element={<RequiresAuthentication><AccountComponent /></RequiresAuthentication>} />
                  <Route path="/invitation" element={<RequiresAuthentication><ServerInvitationComponent /></RequiresAuthentication>} />
                  {hasAdmin && (
                    <Route path="/admin" element={<RequiresAuthentication><AdminComponent /></RequiresAuthentication>}>
                      <Route path="logs/service" element={<RequiresScope requires={['titandash/root', 'titandash/admin']} showMessageOnMissingPermission><ServiceLogs /></RequiresScope>} />
                      <Route path="logs/pot" element={<RequiresScope requires={['titandash/root', 'titandash/admin']} showMessageOnMissingPermission><PotLogs /></RequiresScope>} />
                    </Route>
                  )}
                  <Route path="/server/:serverUuid" element={<RequiresAuthentication><ServerHomeComponent hideTabs /></RequiresAuthentication>}>
                    <Route path="home" element={<RequiresPermission requires={[INTERNAL_PERMISSION.HOME_TAB]} showMessageOnMissingPermission><ServerPlayersTab /></RequiresPermission>} />
                  </Route>
                  <Route path="/server/:serverUuid/admin" element={<RequiresAuthentication><ServerHomeComponent /></RequiresAuthentication>}>
                    <Route path="home" element={<RequiresPermission requires={[INTERNAL_PERMISSION.HOME_TAB]} showMessageOnMissingPermission><ServerPlayersTab /></RequiresPermission>} />
                    <Route path="connectivity" element={<RequiresPermission requires={[INTERNAL_PERMISSION.CONNECTIVITY_TAB]} requiresRule='any' showMessageOnMissingPermission><ServerConnectivityTab /></RequiresPermission>} />
                    <Route path="locations" element={<RequiresPermission requires={[INTERNAL_PERMISSION.LOCATIONS_TAB]} showMessageOnMissingPermission><ServerLocationsTab /></RequiresPermission>} />
                    <Route path="commands" element={<RequiresPermission requires={[INTERNAL_PERMISSION.COMMANDS_TAB]} showMessageOnMissingPermission><ServerCommandsTab /></RequiresPermission>} />
                    <Route path="settings" element={<RequiresPermission requires={[INTERNAL_PERMISSION.SETTINGS_TAB]} showMessageOnMissingPermission><ServerSettingsTab /></RequiresPermission>} />
                    <Route path="roles" element={<RequiresPermission requires={[INTERNAL_PERMISSION.ROLES_TAB]} showMessageOnMissingPermission><RolesComponent /></RequiresPermission>} />
                    <Route path="members" element={<RequiresPermission requires={[INTERNAL_PERMISSION.MEMBERS_TAB]} showMessageOnMissingPermission><ServerMembersTab /></RequiresPermission>} />
                    <Route path="logs" element={<RequiresPermission requires={[INTERNAL_PERMISSION.ACTIVITY_LOGS_TAB]} showMessageOnMissingPermission><ActivityLogsTab /></RequiresPermission>} />
                    <Route path="configs" element={<RequiresPermission requires={[INTERNAL_PERMISSION.SERVER_CONFIG_TAB]} showMessageOnMissingPermission><ServerEditorTab /></RequiresPermission>} />
                    <Route path="webhooks" element={<RequiresPermission requires={[INTERNAL_PERMISSION.READ_WEBHOOKS, INTERNAL_PERMISSION.MANAGE_WEBHOOKS]} showMessageOnMissingPermission><WebhooksComponent /></RequiresPermission>} />
                    <Route path="delete" element={<RequiresPermission requires={[INTERNAL_PERMISSION.DELETE_SERVER]} showMessageOnMissingPermission><DeleteServerTab /></RequiresPermission>} />
                  </Route>
                  <Route path='*' element={<NotFound />} />
                </Routes>
              </Box>
            </Box>
      </Router>
  );
};
