import axios from 'axios';
import { CreatorModeItem, CreatorModeOption } from './creator-modes.types';
import { FriendlyApiError, GenericError } from './types';


const SERVER_ENDPOINT = `/api/server`

const CREATOR_MODE_ENDPOINT = `commands/creator-modes`

export const getCreatorModeItems = async(serverUuid: string): Promise<string[]> => {
    try {

        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/${CREATOR_MODE_ENDPOINT}`);
        const { items } = data;
    
        return items;
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw new GenericError('Failed to retrieve creator mode items')
    }
}

export const getCreatorModeItemOptions = async(serverUuid: string): Promise<CreatorModeOption[]> => {
    const items = await getCreatorModeItems(serverUuid);

    return items.map((item) => ({
        label: item,
        value: item,
        item: {
            id: item,
            name: item,
        },
    }));
}

export const resetCreatorMode = async(serverUuid: string): Promise<void> => {
    try {
        await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${CREATOR_MODE_ENDPOINT}/reset`);
    } catch (e) {
        console.error(e);
    }
}

export const loadCreatorMode = async(serverUuid: string, item: CreatorModeItem): Promise<void> => {
    try {
        await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${CREATOR_MODE_ENDPOINT}/${item.id}/load`);
    } catch (e) {
        console.error(e);
    }
}