import axios from 'axios'

import { AlderonUser, AuthenticationResponse, User } from './auth.types'
import { RCONDetails, ServerDetailsModel, ServerModel, WebhookEndpoints } from './server.types'
import { FriendlyApiError, GenericError } from './types'

const SERVER_ENDPOINT = `/api/server`
const WEBHOOKS_ENDPOINT = `webhooks`
const WEBHOOKS_ROTATE_ENDPOINT = `${WEBHOOKS_ENDPOINT}/rotate`

export const getServers = async(): Promise<ServerModel[]> => {
    try {
        const { data } = await axios.get(SERVER_ENDPOINT)
        const { success, items } = data

        if (!success) {
            throw new GenericError('Failed to retrieve servers for user')
        }

        return items as ServerModel[]
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const createServer = async(name: string, description: string, logo: File | null): Promise<ServerModel> => {
    try {
        const { data } = await axios.post(SERVER_ENDPOINT, { name, description, logo: logo || '' })
        const { success, item } = data

        if (!success || !item) {
            throw new GenericError('Failed to create server')
        }

        return item as ServerModel
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}


export const updateServer = async(serverUuid: string, name: string, description: string, logo: File | null): Promise<Partial<ServerModel>> => {
    try {
        const { data } = await axios.put(`${SERVER_ENDPOINT}/${serverUuid}`, { name, description, logo: logo || '' })
        const { success, item } = data

        if (!success || !item) {
            throw new GenericError('Failed to update server')
        }

        return item as Partial<ServerModel>
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}
export const deleteServer = async(serverUuid: string): Promise<boolean> => {
    try {
        const { data } = await axios.delete(`${SERVER_ENDPOINT}/${serverUuid}`)
        const { success } = data

        if (!success) {
            throw new GenericError('Failed to delete server')
        }

        return true
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const loadServerDetails = async(serverUuid: string): Promise<ServerDetailsModel> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/details`)
        const { success, item } = data

        if (!success || !item) {
            throw new GenericError('Failed to retrieve server details')
        }

        return item as ServerDetailsModel
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const updateRCONConfiguration = async(serverUuid: string, connection: { host: string; port: number; password: string }): Promise<{ item: RCONDetails, registered: boolean }> => {
    try {
        const { host: rcon_host, port: rcon_port, password: rcon_password } = connection
        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/rcon`, { rcon_host, rcon_password, rcon_port: rcon_port.toString() })
        const { success, item, registered } = data

        if (!success || !item) {
            throw new GenericError('Failed to update RCON configuration')
        }

        return { item, registered }
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const removeRCONConfiguration = async(serverUuid: string): Promise<{ registered: boolean, item: RCONDetails}> => {
    try {
        const { data } = await axios.delete(`${SERVER_ENDPOINT}/${serverUuid}/rcon`)
        const { success, item } = data

        if (!success || !item) {
            throw new GenericError('Failed to update RCON configuration')
        }

        return { item, registered: false }
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const getWebhooks = async(serverUuid: string): Promise<WebhookEndpoints> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/${WEBHOOKS_ENDPOINT}`)
        const { success, item } = data

        if (!success || !item) {
            throw new GenericError('Failed to retrieve webhooks')
        }

        return item as WebhookEndpoints
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const rotateWebhooks = async(serverUuid: string): Promise<WebhookEndpoints> => {
    try {
        const { data } = await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${WEBHOOKS_ROTATE_ENDPOINT}`)
        const { success, item } = data

        if (!success || !item) {
            throw new GenericError('Failed to rotate webhooks')
        }

        return item as WebhookEndpoints
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}