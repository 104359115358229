import React from 'react';
import {
  Grid} from '@mui/material';
import ServerCard from './ServerCard';
import { ServerModel } from '../../api/server.types';

interface ServerListProps {
  servers: ServerModel[];
  onServerClicked?: (serverUuid: string) => void;
  onDeleteServer?: (serverUuid: string) => void;
}

const ServerList: React.FC<ServerListProps> = ({ servers, onServerClicked, onDeleteServer }) => {
  return (
    <Grid container spacing={3}>
      {servers.map((server) => (
        <ServerCard key={server.id} item={server} onServerClicked={onServerClicked} onDeleteServer={onDeleteServer} />
      ))}
    </Grid>
  );
};

export default ServerList;
