import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  Chip,
  Grid,
  Box,
  IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ClipLoader from "react-spinners/ClipLoader";
import CheckIcon from '@mui/icons-material/Check';
import CrossIcon from '@mui/icons-material/Cancel';

interface ConnectivityCardProps {
  configured: boolean;
  connected: boolean;
  updating?: boolean;
  registered?: boolean;
  showConfigure?: boolean;
  showDelete?: boolean;
  onConfigure?: () => void; // Prop to handle edit action
  onDelete?: () => void; // Prop to handle delete action
}

const RCONCard: React.FC<ConnectivityCardProps> = ({ configured, connected, updating, registered, showConfigure, showDelete, onConfigure, onDelete  }) => {
  const [cardStyle, setCardStyle] = React.useState({});
  const onConfigureHandler = React.useCallback(() => {
    if (onConfigure) {
      onConfigure();
    }
  }, [onConfigure, ]);
  return (
    <Grid item xs={12} sm={6} md={4} sx={{ mt: 2 }}>
      <Card sx={{
        ...cardStyle,
      }}>
        <CardHeader
          action={
            (showConfigure || showDelete) && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {showConfigure && (
                  <IconButton onClick={onConfigureHandler} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    {configured ? <EditIcon /> : <AddIcon />}
                  </IconButton>
                )}
                {showDelete && configured && (
                  <IconButton onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            )
          }
          title={<Box sx={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>Connectivity Status</Box>}
          sx={{ backgroundColor: '#0003' }}
        />
        <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '7rem' }}>
          <Box sx={{ display: 'flex' }}>
              {configured && <CheckIcon sx={{ color: 'green' }} />}
              {!configured && <CrossIcon sx={{ color: 'red' }} />}
            <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
              {configured ? 'RCON has been configured': `RCON configuration is missing.`}
              {!configured && showConfigure && ' Please configure RCON settings'}
              {!configured && !showConfigure && 'Notify an administrator'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mt: 1 }}>
            {connected && <CheckIcon sx={{ color: 'green' }} />}
            {!connected && <CrossIcon sx={{ color: 'red' }} />}
            <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
            {connected  && 'RCON connection was successfully established'}
            {!connected && 'RCON connection failed!'}
            {!connected && showConfigure && ' Please check RCON settings'}
            {!connected && !showConfigure && ' Notify an administrator'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', mt: 1, alignItems: 'center' }}>
          {registered && <CheckIcon sx={{ color: 'green' }} />}
          {!registered && <CrossIcon sx={{ color: 'red' }} />}
            <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
              {registered  ? 'Server appears to be live!': `Server does not appear to be live! ${connected  ? 'Please check server logs to see if it failed to register' : 'Please confirm your server is up and running or update the RCON configuration.'}`}
            </Typography>
          </Box>
          {updating && (
            <Box sx={{ display: 'flex', mt: 1 }}>
              <ClipLoader />
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default RCONCard;
